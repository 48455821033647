import React from 'react'

type SettingsIconSvgProps = React.ComponentPropsWithoutRef<'svg'>

const SettingsIconSvg = ({ ...props }: SettingsIconSvgProps) => {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      {...props}
    >
      <path
        opacity="0.4"
        d="M8.08329 12.9581H1.50777C0.675552 12.9581 0 13.6218 0 14.4394C0 15.2559 0.675552 15.9207 1.50777 15.9207H8.08329C8.91551 15.9207 9.59106 15.2559 9.59106 14.4394C9.59106 13.6218 8.91551 12.9581 8.08329 12.9581Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M20 3.37869C20 2.56216 19.3244 1.89845 18.4933 1.89845H11.9178C11.0856 1.89845 10.41 2.56216 10.41 3.37869C10.41 4.19631 11.0856 4.86001 11.9178 4.86001H18.4933C19.3244 4.86001 20 4.19631 20 3.37869Z"
        fill="currentColor"
      />
      <path
        d="M6.87774 3.37868C6.87774 5.24535 5.33886 6.75833 3.43887 6.75833C1.53999 6.75833 0 5.24535 0 3.37868C0 1.51311 1.53999 0.00012207 3.43887 0.00012207C5.33886 0.00012207 6.87774 1.51311 6.87774 3.37868Z"
        fill="currentColor"
      />
      <path
        d="M20.0001 14.3993C20.0001 16.2649 18.4612 17.7779 16.5612 17.7779C14.6623 17.7779 13.1223 16.2649 13.1223 14.3993C13.1223 12.5327 14.6623 11.0197 16.5612 11.0197C18.4612 11.0197 20.0001 12.5327 20.0001 14.3993Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const SettingsIcon = React.memo(SettingsIconSvg)
